/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { Select, getStyles } from '@conversionbuddy/Layout'
import { useAttributes } from './modules'
import { useTranslation } from '../../../hooks/useTranslation'

const StyledButtonSelect = styled.button`
    ${(props) =>
        getStyles([
            'attributes.button',
            props.selected && 'attributes.button.selected',
            props.isDisabled &&
                'attributes.button.disabled',
        ])(props)}
`

const StyledAttributesButtonSelectLabel = styled.div`
    ${(props) =>
        getStyles([
            'attributes.buttonSelect.label',
            props.grid &&
                'productsGrid.product.details.attributes.buttonSelect.label',
        ])(props)}
`

const StyledAttributesButtonSelectContainer = styled.div`
    ${(props) =>
        getStyles([
            'attributes.buttonSelect.container',
            props.grid &&
                'productsGrid.product.details.attributes.buttonSelect.container',
        ])(props)}
`

const StyledAttributesButtonSelectGroup = styled.div`
    ${(props) =>
        getStyles([
            'attributes.buttonSelect.group',
            props.grid &&
                'productsGrid.product.details.attributes.buttonSelect.group',
        ])(props)}
`

const ButtonSelect = ({
    grid,
    label,
    onClick,
    options,
    styles,
    value,
}) => {
    const renderOptions = () => {
        return options.map((option) => {
            const handleClick = () => {
                onClick(option.value)
            }
            return (
                <StyledButtonSelect
                    key={option.value}
                    borderColor={styles.borderColor}
                    isDisabled={option.disabled}
                    selected={option.value === value}
                    onClick={handleClick}
                >
                    {option.value}
                </StyledButtonSelect>
            )
        })
    }

    return (
        <StyledAttributesButtonSelectContainer grid={grid}>
            <StyledAttributesButtonSelectLabel grid={grid}>
                {label}
            </StyledAttributesButtonSelectLabel>
            <StyledAttributesButtonSelectGroup grid={grid}>
                {renderOptions()}
            </StyledAttributesButtonSelectGroup>
        </StyledAttributesButtonSelectContainer>
    )
}

ButtonSelect.propTypes = {
    options: PropTypes.array,
    label: PropTypes.object,
    onClick: PropTypes.func,
    value: PropTypes.string,
    styles: PropTypes.object,
    grid: PropTypes.bool,
}

const Label = ({ uc }) => {
    const ucTranslations = useTranslation(
        'uniqueCharacteristics',
    )
    const uniqueCharacteristic = ucTranslations[uc]
    const translationCallback = (_, value) => value

    const label = useTranslation(
        'dropdownLabel',
        { uniqueCharacteristic },
        translationCallback,
    )

    return label
}

export const Attributes = ({
    activeSku,
    product,
    type,
    grid,
    onDeselect,
    onSelect,
}) => {
    const maxWidth = useSelector(
        (state) =>
            state.general?.uniqueCharacteristics
                ?.dropdownThreshold?.max || 240,
    )

    const [selection, optionsByUc, select] = useAttributes(
        product,
        activeSku,
        false,
    )

    const optionsList = Object.entries(optionsByUc).map(
        ([uniqueCharacteristic, options]) => ({
            uniqueCharacteristic,
            options,
        }),
    )

    const estimatedContainerWidth =
        optionsList?.[0]?.options?.reduce(
            (w, o) => w + 15 + o.value.length * 5.5,
            0,
        )

    const theme = useTheme()

    const handleChange = (uc) => (option) => {
        const sku = select(
            uc,
            option === '-1' ? null : option,
        )

        if (sku) {
            onSelect(sku)
        }

        if (option === '-1' && onDeselect) {
            onDeselect()
        }
    }

    const calculateRenderType = () => {
        if (type) {
            return type
        }

        if (estimatedContainerWidth > maxWidth) {
            return 'select'
        }

        if (product.uniqueCharacteristics?.length > 1) {
            return 'select'
        }

        if (product.uniqueCharacteristics?.length === 0) {
            return 'none'
        }

        return 'buttons'
    }

    const render = () => {
        const type = calculateRenderType()

        if (type === 'none') {
            return null
        }

        const Element =
            type === 'buttons' ? ButtonSelect : Select

        return optionsList.map(
            ({ uniqueCharacteristic, options }, idx) => (
                <Element
                    data-test-id='attributes'
                    key={uniqueCharacteristic}
                    grid={grid}
                    label={
                        <Label uc={uniqueCharacteristic} />
                    }
                    options={options}
                    removeable={
                        Object.keys(selection)?.length > 1
                    }
                    styles={{
                        borderColor:
                            theme.variables.colors.border,
                        marginTop: idx > 0 ? '.75rem' : 0,
                    }}
                    value={selection[uniqueCharacteristic]}
                    onChange={handleChange(
                        uniqueCharacteristic,
                    )}
                    onClick={handleChange(
                        uniqueCharacteristic,
                    )}
                />
            ),
        )
    }
    return render()
}

Attributes.defaultProps = {
    grid: false,
}

Attributes.propTypes = {
    activeSku: PropTypes.object,
    grid: PropTypes.bool,
    onSelect: PropTypes.func,
    product: PropTypes.object,
    type: PropTypes.string,
    onDeselect: PropTypes.func,
}
