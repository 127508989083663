import {
    disablePageScroll,
    enablePageScroll,
    getScrollState,
} from 'scroll-lock'

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0
}

// const mapUntil = (arr, n, fn) => {
//     return arr.map((t, i) => {
//         if (i < n) {
//             return fn(t)
//         }

//         return t
//     })
// }

export const times = (n, cb) => {
    const result = []
    for (let i = 0; i < n; i += 1) {
        result.push(cb(i))
    }
    return result
}

export const modIndex = (i, l) => {
    let pos = i
    while (pos < 0) {
        pos += l
    }
    return pos % l
}

export const equalModIndex = (a, b, l) => {
    return modIndex(a, l) === b
}

const reverseString = (str) =>
    str.split('').reverse().join('')

const chunkString = (str, l) => {
    const chunks = []
    let rest = str.slice()
    for (let i = 0; i < Math.ceil(str.length / l); i += 1) {
        const c = rest.slice(0, l)
        chunks.push(c)
        rest = rest.slice(l, rest.length)
    }
    return chunks
}

const duplicateString = (str) => {
    return [str, str]
}

export const formatPrice = (price, config) => {
    if (!config || !price) {
        return price
    }

    const sign = duplicateString(
        config[config.display],
    ).map((t, i) => {
        if (config.position === 'after') {
            if (i === 0) {
                return ''
            }

            return ` ${t}`
        }

        if (config.position === 'before') {
            if (i === 0) {
                return `${t} `
            }

            return ''
        }
    })

    const priceAsString = price
        .toFixed(config.decimal)
        .replace('.', config.separator.decimal)
        .split(config.separator.decimal)
        .map((t, i) => {
            if (i < 1) {
                return chunkString(reverseString(t), 3)
                    .reverse()
                    .map((t) => reverseString(t))
                    .join(config.separator.thousand)
            }

            return t
        })

    return `${sign[0]}${priceAsString.join(
        config.separator.decimal,
    )}${sign[1]}`
}

export const clearNullishValues = (obj) => {
    // TODO: deep?
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key]
        if (value === null || value === undefined) {
            return acc
        }
        acc[key] = value
        return acc
    }, {})
}

/**
 * Removes shallowly all properties if keys included key
 * @param {*} obj
 * @param {*} keys
 * @returns obj
 */
export const omit = (obj, keys) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (keys.includes(key)) {
            return acc
        }

        acc[key] = obj[key]
        return acc
    }, {})
}

export const omitBy = (obj, pred = noop) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (pred(obj[key], key)) {
            return acc
        }

        acc[key] = obj[key]
        return acc
    }, {})
}

export const omitKeys = (obj, keys) =>
    omitBy(obj, (_, key) => keys.includes(key))

/* istanbul ignore next */
export const scrollLock = {
    enable: () => {
        if (window.innerWidth > 900) {
            disablePageScroll()
        }
    },
    /* istanbul ignore next */
    disable: () => {
        if (!getScrollState()) {
            enablePageScroll()
        }
    },
}

export const distinct = (array) => {
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }

    return array.filter(unique)
}

export const combine = (a = [], b = []) =>
    times(Math.max(a.length, b.length), (i) => [a[i], b[i]])

export const cutTextByDots = (
    text,
    maxLength,
    minLength,
) => {
    if (text.length < maxLength) {
        return text
    }

    return text
        .split(' ')
        .reduce(
            ({ b, t }, token) => {
                if (b) {
                    const next = [t, token].join(' ')
                    if (
                        minLength &&
                        next.length > minLength
                    ) {
                        if (token.indexOf('.') !== -1) {
                            return {
                                b: false,
                                t: `${[
                                    t,
                                    token.split('.')[0],
                                ].join(' ')}.`,
                            }
                        }

                        if (token.indexOf('!') !== -1) {
                            return {
                                b: false,
                                t: `${[
                                    t,
                                    token.split('!')[0],
                                ].join(' ')}!`,
                            }
                        }
                    }

                    if (`${next}...`.length <= maxLength) {
                        return {
                            t: next,
                            b,
                        }
                    }
                    const lastChar = t[t.length - 1]
                    const normalized = [',', '.'].includes(
                        lastChar,
                    )
                        ? t.slice(0, t.length - 1)
                        : t
                    return {
                        t: `${normalized}...`,
                        b: false,
                    }
                }
                return { b, t }
            },
            { t: '', b: true },
        )
        .t.trim()
}

/* istanbul ignore next */
export const redirect = (target) => {
    window.location = target
}

/* istanbul ignore next */
export const openTab = (target) => window.open(target)

/* istanbul ignore next */
export const noop = () => {}

export const uniqBy = (arr, pred) => {
    return arr.reduce((acc, val) => {
        if (acc.find((en) => pred(en, val))) {
            return acc
        }
        return [...acc, val]
    }, [])
}

export const inRange = (number, start, end) => {
    return number >= start && number <= end
}

export const getCurrentISODate = () => {
    return new Date().toISOString()
}

/**
 * Returns value if options includes value, otherwise null
 * @param {String} value
 * @param {Array[String]} options
 */
export const oneOf = (value, options = []) => {
    return options.indexOf(value) !== -1 ? value : null
}

/**
 * Returns value when value is not null or undefined, otherwise defaultValue
 * @param {*} value
 * @param {*} defaultValue
 */
export const defaultsTo = (value, defaultValue) => {
    if (value === null || value === undefined) {
        return defaultValue
    }

    return value
}

export const createAriaLabelFromProduct = (product) => {
    const category = product.categoryPath
        .split('>')
        .pop()
        .trim()
    const label = `${category} ${product.title}`
    return label
}

export const identity = (x) => x
export const clamp = (x, from, to) => {
    if (x < from) {
        return from
    }

    if (x > to) {
        return to
    }

    return x
}

export const difference = (arr1 = [], arr2 = []) => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    const added = arr1.filter((item) => !set2.has(item))
    const removed = arr2.filter((item) => !set1.has(item))

    return {
        added,
        removed,
    }
}

export const isEven = (num = 1) => num % 2 === 0

export const hasKeys = (o, ks) => ks.every((k) => o[k])

export const omitMissingValues = (obj) =>
    omitBy(obj, (val) => !val)

export const uniq = (arr) => {
    return arr.reduce((acc, val) => {
        if (acc.indexOf(val) === -1) {
            return [...acc, val]
        }
        return acc
    }, [])
}
