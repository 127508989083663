import { useState, useMemo } from 'react'
import {
    hasKeys,
    omitKeys,
    omitMissingValues,
    uniq,
} from '../../../../utils'
import { sort } from '../../../../utils/sort'

const getMatches = (selection, skus) =>
    skus.filter((sku) =>
        Object.entries(selection).every(
            ([uc, value]) => sku[uc] === value,
        ),
    )

export const useAttributes = (
    product,
    activeSku,
    isCombined = true,
) => {
    const uniqueCharacteristics = useMemo(
        () =>
            isCombined && product.uniqueCharacteristics
                ? ['combined']
                : product.uniqueCharacteristics || [],
        [isCombined, product],
    )

    const skus = useMemo(
        () =>
            isCombined
                ? product.skus.map((sku) => ({
                      ...sku,
                      combined: (
                          product.uniqueCharacteristics ||
                          []
                      )
                          .map((uc) => sku[uc])
                          .join(' | '),
                  }))
                : product.skus,
        [isCombined, product],
    )

    const preselectedSku = useMemo(
        () =>
            activeSku
                ? skus.find(
                      (sku) => sku.sku === activeSku.sku,
                  )
                : null,
        [activeSku, skus],
    )

    const [selection, setSelection] = useState(
        preselectedSku
            ? uniqueCharacteristics.reduce(
                  (acc, uc) =>
                      omitMissingValues({
                          ...acc,
                          [uc]: preselectedSku[uc],
                      }),
                  {},
              )
            : {},
    )

    // wird nur bei init berechnet, da sich product nie ändert
    const distinctValuesByUc = useMemo(
        () =>
            uniqueCharacteristics.reduce(
                (acc, uc) => ({
                    ...acc,
                    [uc]: sort(
                        uniq(skus.map((sku) => sku[uc])),
                    ),
                }),
                {},
            ),
        [uniqueCharacteristics, skus],
    )

    // wird nur bei init oder bei änderung der selection berechnet
    const matchesByUc = useMemo(
        () =>
            uniqueCharacteristics.reduce(
                (acc, uc) => ({
                    ...acc,
                    [uc]: getMatches(
                        omitKeys(selection, [uc]),
                        skus,
                    ),
                }),
                {},
            ),
        [uniqueCharacteristics, skus, selection],
    )

    const optionsByUc = useMemo(
        () =>
            Object.entries(distinctValuesByUc).reduce(
                (acc, [uc, distinctValues]) => ({
                    ...acc,
                    [uc]: distinctValues.map((value) => ({
                        value,
                        disabled: !matchesByUc[uc].find(
                            (m) =>
                                m[uc] === value &&
                                m.availability ===
                                    'in_stock',
                        ),
                    })),
                }),
                {},
            ),
        [distinctValuesByUc, matchesByUc],
    )

    const select = (uc, value) => {
        const v = value === '-1' ? null : value
        const nextSelection = omitMissingValues({
            ...selection,
            [uc]: v,
        })

        setSelection(nextSelection)

        return hasKeys(nextSelection, uniqueCharacteristics)
            ? skus.find((sku) =>
                  Object.entries(nextSelection).every(
                      ([uc, val]) => sku[uc] === val,
                  ),
              )
            : null
    }

    return [selection, optionsByUc, select]
}
